import React, { useEffect, useState } from "react";
import styles from "./AddToMeetingDialog.module.scss";
import _ from "lodash";
import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import useForm from "../../hooks/useForm";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, useMediaQuery, Select, MenuItem, FormControl, FormHelperText, InputLabel } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Loading from "../Loading/Loading";
import { format, isToday } from "date-fns";

import PlanPill from "../PlanPill/PlanPill";
import { MEETINGS_SUBSCRIPTION } from "../../utils/subscriptions";
import { GET_MEETINGS } from "../../utils/query";

import useAddToMeeting from "../../hooks/useAddToMeeting";

const getDateString = (date) => {
  const dateObj = new Date(parseInt(date));
  const dateIsToday = isToday(dateObj);
  const formattedDate = format(dateObj, "MMM dd, yyyy");
  const formattedTime = format(dateObj, "h:mm a");

  return `${dateIsToday ? "today" : formattedDate} @ ${formattedTime}`;
};

const AddToMeetingDialog = ({ dialog, setDialog, params, snack, user }) => {
  const { data, loading, subscribeToMore } = useQuery(GET_MEETINGS, {
    variables: {
      organization: params.org,
      sharedPlanId: user.departmentFilter.sharedPlanId,
      meetingUser: user.user.id,
    },
    fetchPolicy: "network-only",
  });

  useEffect(
    () => {
      if (loading) {
        return;
      }

      const unsubscribe = subscribeToMore({
        document: MEETINGS_SUBSCRIPTION,
        variables: { meetingUser: user.user.id },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) {
            return prev;
          }

          const meeting = subscriptionData.data.meetingPayload.meetingMutated;
          const action = subscriptionData.data.meetingPayload.action;

          // Initialize prev.meetings if it's null, undefined, or empty
          const currentMeetings = prev?.meetings || [];

          let newMeetings;
          switch (action) {
            case "update":
              newMeetings = currentMeetings.map((t) => (t.id === meeting.id ? meeting : t));
              break;
            case "delete":
              newMeetings = currentMeetings.filter((t) => t.id !== meeting.id);
              break;
            case "create":
              const meetingExists = currentMeetings.some((t) => t.id === meeting.id);
              newMeetings = meetingExists ? currentMeetings : [...currentMeetings, meeting];
              break;
            default:
              break;
          }

          return Object.assign({}, prev, {
            meetings: newMeetings,
          });
        },
      });
      return () => {
        unsubscribe();
      };
    },
    [loading, subscribeToMore, user.user.id]
  );

  const {
    data: planData,
    refetch: planDataRefetch,
    loading: planDataLoading,
  } = useQuery(GET_PLANS, {
    variables: { organization: params.org },
  });

  useEffect(() => {
    if (planData) {
      const sharedPlanId = _.get(dialog, "addToMeetingDialog.sharedPlanId", null);
      if (sharedPlanId) {
        //doesn't matter what plan year, we are just using this to populate the pill with the department name
        const departmentPlan = _.find(planData.plans, { sharedPlanId });
        setDepartmentPlan(departmentPlan);
      }
      else {
        setDepartmentPlan(null);
      }
    }
  }, [planData, dialog.addToMeetingDialog.sharedPlanId]);


  const [departmentPlan, setDepartmentPlan] = useState(null);
  const [filteredMeetings, setFilteredMeetings] = useState([]);  
  const [meeting, setMeeting] = useState({});
  
  const fs = useMediaQuery("(max-width: 600px)");

  const { addToMeeting } = useAddToMeeting();

  const initForm = {
    meeting: null,
  };
  const initErrorForm = {
    meeting: ["required"]   
  };

  const { form, formErrors, handleChange, handleChangeManual, resetForm, validateForm } = useForm({
    initForm,
    initErrorForm,
  });

  const handleMeetingChange = (event) => { 
    const meetingId = event.target.value;
    setMeeting(filteredMeetings.find(meeting => meeting.id === meetingId));
    handleChangeManual({ name: "meeting", value: meetingId });

  };

  const handleSubmit = (close) => async () => {
    if (!validateForm()) return;

    const referenceId = _.get(dialog, "addToMeetingDialog.referenceId", null);
    const referenceModel = _.get(dialog, "addToMeetingDialog.referenceModel", null);
    if (!referenceId || !referenceModel) return;
 
    if (await addToMeeting(meeting, referenceId, referenceModel)) {
      snack(`Added successfully to the meeting`);
    }
    else {
      snack(`Unable to add to the meeting`, `error`);
    }
    //requestFetch();
    resetForm();

    if (close) {
      handleClose();
    }
    
  };

  const handleClose = () => {
    setDialog({ ...dialog, addToMeetingDialog: { open: false } });
  };

  useEffect(() => {
    if (_.get(dialog, "addToMeetingDialog.open", false)) {
      const existingMeeting = _.get(dialog, "addToMeetingDialog.meeting", {});
      setMeeting(existingMeeting);
    }
  }, [dialog.addToMeetingDialog]);


   useEffect(() => {

    let matchedMeetings = [];
    const meetings = _.get(data, "meetings", []);
    if (meetings) {
      matchedMeetings = meetings.filter(({ steps = [] }) =>
        steps.some(({ content = [], value }) =>
          content.some(({ type }) => type === 'rockReview') || value === 'rocks'
        )
      )
      .filter(meeting => {   
        
        if ( _.get(meeting, "status") === 'finished') {
          return false;
        }

        //at this point in time, no item can be added to a meeting step that doesn't require a department
        //to be selected, so if sharedPlanID is null, we are done.
        if (!_.isNull(meeting.sharedPlanId) && (meeting.sharedPlanId === _.get(dialog, "addToMeetingDialog.sharedPlanId", null))) {
          //if the meeting has a particular plan year selected, then check that the corporate plans match
          if (_.isNull(meeting.plan) || (meeting.plan === _.get(dialog, "addToMeetingDialog.planId", null))) {
            return true;
          }
        }

        return false;
      });
    }

    setFilteredMeetings(!_.isEmpty(matchedMeetings) ? matchedMeetings : []);


  }, [data, dialog.addToMeetingDialog]);
  
  useEffect(() => {
    if (Boolean(dialog.addToMeetingDialog.open) && form.meeting && !_.find(filteredMeetings, { id: form.meeting })) {
      setMeeting(null);
      resetForm();
    }
  }, [form.meeting, dialog.addToMeetingDialog.open]);

  if (loading || !data) return <Loading />;

  return (
    <Dialog
      open={Boolean(dialog.addToMeetingDialog.open)}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
      fullWidth
      fullScreen={fs}
    >
      <DialogTitle>
        <div className={styles.title}>
          {'Address in Meeting'}
          <div>
            <IconButton onClick={handleClose} size="small">
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </div>
        </div>
      </DialogTitle>

      <DialogContent>
          <FormControl fullWidth variant="outlined" margin="normal" error={formErrors.meeting} data-test='a2m-select-meeting'>
          <InputLabel>Select a Meeting</InputLabel>
            <Select
              name="meeting"
              value={form.meeting || ""}
              onChange={handleMeetingChange}
              fullWidth
              variant="outlined"
              margin="normal"
              label="Select a Meeting"
              error={Boolean(formErrors.meeting)}
            >
              { filteredMeetings && filteredMeetings.length > 0 ? (
                filteredMeetings.map((meeting) => (
                  <MenuItem key={meeting.id} value={meeting.id}>
                    <PlanPill plan={departmentPlan} />
                    {meeting.title} ({getDateString(meeting.startTime)})
                  </MenuItem>
                )) 
              ) : (
                <MenuItem value={""}>No valid meetings found</MenuItem>
              )}                              
            </Select>
            <FormHelperText error={formErrors.meeting}>{formErrors.category}</FormHelperText>
          </FormControl>
      </DialogContent>
      <DialogActions>

        <Button onClick={handleClose}>Cancel</Button>
        <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit(true)}
            disabled={_.isNil(form.meeting)}
        >
            {"Add"}
        </Button>            
      </DialogActions>
    </Dialog>
  );
};

export default AddToMeetingDialog;

const GET_PLANS = gql`
  query AddToMeetingDialog_GetPlans($organization: ID!) {
    plans(organization: $organization, category: "1 year") {
      id
      departmentName
      sharedPlanId
      year
    }
  }
`;